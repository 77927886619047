.fr-wrapper {
    min-height: 140px;
}

.fr-wrapper a:first-child {
    display: none;
}

.viewonly .ck-content {
    border: none !important;
}

.ck-content {
    min-height: 400px;
}

.create-document-form-selector .ant-select-selector {
    @apply rounded-none !important;
}

.document-updator .ant-select-selector {
    @apply rounded-none !important;
}

.select-no-margin {
    margin: 0 !important;
}
