.user-card-skeleton .ant-skeleton-paragraph {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

ul.ant-skeleton-paragraph {
  margin: 7px 0;
}

.user-card-skeleton.title {
  margin: 17px 0 12px;
}

.user-card-skeleton.title.button.action {
  margin-right: 40px;
}

.user-card-skeleton.title.button.info {
  margin-left: 60px;
}

.ant-btn.list-btn-user {
  padding: 4px 0 !important;
}

.ant-input-group-wrapper.ant-input-group-wrapper-lg.user-search
  span
  .ant-input-group-addon {
  background-color: #ff9900;
  color: #fff;
  border-color: #ff9900;
}

.ant-input-group-wrapper.ant-input-group-wrapper-lg.user-search
  span
  .ant-input-outlined {
  border-color: #d9d9d9;
  font-size: 0.9rem;
  padding: 8px 11px;
}

.ant-input-group-wrapper.ant-input-group-wrapper-lg.user-search.none-option
  span
  .ant-input-outlined {
  border-right: 1px solid #d9d9d9;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-input-group-wrapper.ant-input-group-wrapper-lg.user-search-log
  span
  .ant-input-outlined {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
}

.ant-input-group-wrapper.ant-input-group-wrapper-lg.user-search
  span
  .ant-input-outlined:hover {
  border-color: #ff9900;
}

.ant-input-group-wrapper.ant-input-group-wrapper-lg.user-search
  span
  .ant-input-outlined:focus-within {
  border-color: #ff9900;
}

.user-search-select.ant-select.ant-select-lg.ant-select-outlined
  .ant-select-selector {
  font-size: 0.9rem !important;
}

.user-search-select-log.ant-select.ant-select-lg.ant-select-outlined
  .ant-select-selector {
  font-size: 0.9rem !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.ant-btn-default.load-more-btn,
.confirm-btn
  div
  .ant-modal-content
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-btns
  .ant-btn-default {
  border-color: #ff9900 !important;
}

.ant-btn-default.load-more-btn:hover,
.confirm-btn
  div
  .ant-modal-content
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-btns
  .ant-btn-default:hover {
  background-color: #ff9900 !important;
  border-color: #ff9900 !important;
  color: #fff !important;
}

.align-right {
  margin-left: auto;
  display: block;
}

.ant-picker-large .ant-picker-input > input {
  font-size: 0.9rem !important;
  padding: 1px 0;
}
